@import 'styles/mixins';
@import 'styles/variables';

.container {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media('>=screen-sm') {
    flex-wrap: nowrap;
  }
}

.item {
  min-height: 45px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 3px;
  justify-content: space-between;

  @include media('>=375px') {
    width: 50%;
    justify-content: flex-start;
    margin-bottom: 20px;

    &:nth-child(n+3) {
      margin-bottom: 0;
    }
  }

  @include media('>=screen-sm') {
    width: auto;
    margin-bottom: 0;
  }
}

.title {
  font-size: 16px;
  margin: 0;
  width: 75%;

  @include media('>=375px') {
    padding-left: 15px;
    max-width: 150px;
    width: auto;
  }

  @include media('>=screen-sm') {
    max-width: none;
    font-weight: bold;
    font-size: 16px;
  }
  @include media('>screen-md') {
    font-size: 18px;
  }
}



.icon {
  font-size: 30px;
  @include media('>=screen-sm') {
    font-size: 32px;
    height: auto;
  }
  @include media('>screen-md') {
    font-size: 40px;
  }
}

.iconInsurance {
  font-size: 40px;
  margin-left: -5px;

  @include media('>=375px') {
    font-size: 30px;
  }
  @include media('>=screen-sm') {
    font-size: 36px;
    margin-left: 0;
  }

  @include media('>screen-md') {
    font-size: 48px;
  }
}

.iconInformation{
  font-size: 30px;

  @include media('>=screen-sm') {
    font-size: 30px;
  }

  @include media('>screen-md') {
    font-size: 36px;
  }
}


.logo {
  position: relative;
  width: 30px;
  height: 25px;

  @include media('>=screen-sm') {
    width: 60px
  }
  @include media('>screen-md') {
    width: 70px;
  }
}
