@import 'styles/variables';

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1);
  height: 100%;
  font-size: 14px;
}

.link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.imgWrapper {
  padding-bottom: 60%;
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 3px;
}

.img {
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  object-fit: cover;
}

.dcTitle {
  color: #657381;
}

.info {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  font-size: 16px;
  margin-top: auto;
  justify-content: flex-end;

  span {
    font-weight: bold;
  }
}

.availability {
  color: $green-500;
  margin-bottom: 3px;
}

.inclusion {
  margin-bottom: 3px;
}

.oldPrice {
  font-size: 14px;
  color: $gray-500;
  text-decoration: line-through;
}

.iconLightning {
  color: $yellow-700;
  padding-left: 5px;
}
