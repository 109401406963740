@import 'styles/mixins';
@import 'styles/variables';

.homepageHero {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  position: relative;
  min-height: 400px;
  padding: 0 16px;
  position: relative;
}

.picture {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.searchWrapper {
  margin-top: auto;
  width: 100%;
}

.title {
  color: #fff;
  font-weight: bold;
  text-transform: none;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 1.2;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 24px;

  @media (min-width: $screen-sm) {
    font-size: 36px;
  }

  @media (min-width: $screen-md) {
    font-size: 40px;
  }

  @media (min-width: $screen-lg) {
    font-size: 48px;
  }
}

.laPopup {
  width: 100%;
  max-width: 768px;
  position: absolute;
  left: -9999px;
  opacity: 0;
  transition: opacity 0.3s linear;
  z-index: 99999;

  @include media('>=screen-md') {
    max-width: 1024px;
  }
}

.awardSection {
  max-width: 570px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 24px auto 0;
  background: #002A4D;
  gap: 10px;
  padding: 10px 20px;
  @include media('<=screen-xs') {
    padding: 5px;
    gap: 5px;
  }

}

.description {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;

  & .awardHeader {
    font-size: 16px;
  }
}
