@import 'styles/mixins';
@import 'styles/variables';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.column {
  width: 100%;
  flex-grow: 1;


  @include media('<screen-md') {
    &:last-child {
      .item:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media('>=screen-md') {
    width: 48%;
    margin-right: 2%;
  }

  @include media('>=screen-md') {
    width: 23%;
    margin-right: 2%;
  }
}

.item {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  width: 100%;
  margin-bottom: 6px;
  border-bottom: 1px solid $gray-300;

  @include media('>=screen-md') {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:after {
    content: '\e925';
    font-family: 'icomoon';
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 0;

    @include media('>=screen-md') {
      top: 6px;
    }
  }

  &.active {
    &:after {
      content: '\e924';
    }

    .destinationsWrapper {
      visibility: visible;
      opacity: 1;
      max-height: 100vh;
    }
  }
}

.destinationsWrapper {
  transition: all 0.3s linear;
  visibility: hidden;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.title {
  position: relative;
  z-index: 2;
  font-size: 16px;
  padding: 10px 0;
  color: $gray-900;
  margin-bottom: 0;
  padding-right: 20px;

  @include media('>=screen-md') {
    font-size: 20px;
  }
}

.destinations {
  color: $gray-900;
  width: 100%;
}

.destination {
  margin-bottom: 16px;
}

.link {
  color: $gray-900;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.destinationsTitle {
  padding: 16px 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.usefulLinksSection {
  padding-top: 128px;
}

.usefulLinksHeader {
  padding-bottom: 20px;
  font-size: 25px;
  font-weight: normal;
}

.usefulLink {
  font-size: 24px;
}

.icon {
  font-size: 50px;
  vertical-align: middle;
  line-height: 21px;
  display: inline-block;
}
