.notFound {
  display: block;
  font-size: 20px;
  font-weight:bold;
  max-width: 820px;
  margin: 0 auto;
  text-align: center;
  margin-top: 24px;
  padding-bottom: 24px;
}
