@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/dg-variables';

.headerNav {
  display: flex;
  padding-left: 18px;
  @media (max-width: $screen-md - 1) {
    display: none;
  }
}

.headerLink {
  display: flex;
  font-weight: bold;
  height: 100%;
  align-items: center;
  text-align: center;
  padding: 0 12px;
  color: $default-text-color;
  cursor: pointer;
  position: relative;
  line-height: 1.33;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  position: relative;

  @include media('<screen-lg') {
    font-size: 13px;
  }

  @include media('<screen-lg-shop') {
    padding: 0 8px;
    // overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    color: $blue-700;
  }
}

.menu {
  padding: 32px;
  display: none;
  z-index: 100;
  white-space: normal;
  top: 55px;
  left: 64px;
  right: 64px;
  background: #fff;
  position: absolute;
  box-shadow: 0 10px 24px 0 rgb(0 0 0 / 30%), 0 2px 5px 0 rgb(0 0 0 / 20%);
  max-width: 1400px;
  font-size: 14px;
  line-height: 20px;
}

.staticMenu {
  position: static;
}

.smallMenu {
  position: relative;
}

.hint {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: normal;
  color: $gray-500;
}

.dropDown {
  width: 300px;
  position: absolute;
  top: 30px;
  right: 0;
  background: #fff;
  display: none;
  z-index: 100;
  border-radius: 4px;
  box-shadow: 0 10px 24px 0 rgb(0 0 0 / 20%), 0 2px 5px 0 rgb(0 0 0 / 20%);
  padding: 15px;
}

.dropDownListItem {
  border: 1px solid $gray-200;
  &:nth-child(2) {
    border-top: none;
    border-bottom: none;
  }
}

.dropDownLink {
  display: inline-block;
  padding: 8px 28px 8px 10px;
  font-size: 16px;
  width: 100%;
  color: #000;
  line-height: 1.2;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background: $gray-300;
  }
}

.activeMenu {
  display: block;
}

.listWrapper {
  columns: 5;
}

.list {
  width: auto;
  padding-left: 0;
  padding-right: 15px;
  min-height: 1px;
  text-align: left;
}

.listItem {
  display: inline-block;
  width: 100%;
}

.firstLevelItems {
  margin-bottom: 10px;
}

.innerLevel {
  margin-left: 10px;
}

.link {
  font-size: 14px;
  line-height: 1.43;
  color: #333;
  text-decoration: none;
  padding: 5px 0;
  display: block;
}

.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
}

.btn {
  line-height: 18px;
  padding: 10px;
  text-align: center;
  text-transform: none;
  font-size: 16px;
  color: #333;
  display: block;
}

.themeBtn {
  margin-bottom: 10px;
}
