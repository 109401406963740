@import 'styles/variables';
@import 'styles/mixins';

.dealLabel {
  position: relative;
  display: inline-block;
  height: 20px;
  background-color: $green-500;
  line-height: 20px;
  color: #fff;
  font-size: 12px;
  padding: 0 5px;
  border-radius: 0 3px 3px 0;
  margin-left: 10px;
  margin-bottom: 5px;

  &:before {
    position: absolute;
    right: 100%;
    top: 0;
    height: 20px;
    width: 11px;
    content: '';
    background-image: url('../public/discount.svg');
  }

  @include media('<=screen-md') {
    position: relative;
    height: 16px;
    line-height: 16px;
    left: auto;

    &:before {
      height: 16px;
      background-size: 16px 17px;
    }
  }
}
