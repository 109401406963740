@import 'styles/variables';

.itemWrapper {
  height: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: $box-shadow-default;
  color: $gray-900;
  height: 100%;
  padding: 16px;
  text-decoration: none;

  .features {
    margin-bottom: 32px;
    li {
      margin-bottom: 8px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $gray-900;
    outline: none;
  }
}

.imgWrapper {
  padding-bottom: 60%;
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 3px;
}

.img {
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  object-fit: cover;
}

.info {
  padding: 11px 0 0;
}

.shopTitle {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 3px;
}

.features {
  margin-top: 8px;
  margin-bottom: 16px;
}

.featureItem {
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  margin-bottom: 4px;
}

.icon {
  font-style: normal;
  font-size: 24px;
  height: 18px;
  width: 28px;
  line-height: 18px;
}

.priceWrap {
  margin-top: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .deal-label {
    margin-left: 10px;
    margin-bottom: 5px;
  }
}


.price {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  color: $gray-900;
  margin-bottom: 0;
}

.priceType {
  color: $gray-700;
  font-weight: 500;
}
.priceDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
};

.spotsLeft {
  font-size: 12px;
  color: $red-700;
  text-transform: lowercase;
}

.spotsNumber {
  position: absolute;
  right: 0;
  bottom: 0;
  color: $red-700;
  font-size: 12px;
  font-weight: bold;
}

.soldOut {
  width: 100%;
  color: $red-700;
  font-weight: bold;
}
  