@import 'styles/variables';

.placeholder {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
}

.imgWrapper {
  padding-bottom: 60%;
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 3px;
  background: $gray-300;
}
.info {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 10px;
  & > * {
    display: block;
    width: 100%;
    height: 24px;
    background: $gray-100;
  }

}
.price {
  background: $gray-300;
  width: 60%;
}

.dcTitle {
  margin-bottom: 10px;
}
