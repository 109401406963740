@import 'styles/variables';
@import 'styles/mixins';

.destinationWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  @include media('>=screen-sm') {  
    padding: 24px 0;
  }
}

.item {
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 15px;

  @include media('<screen-sm') {  
    &:nth-child(n + 2) {
      width: 48%;
      padding-bottom: 35%;
      margin-bottom: 15px;
      .title {
        font-size:  16px;
      }
    }
  }

  @include media('>=screen-sm') {  
    width: 49%;
    padding-bottom: 35%;
    margin-bottom: 32px;

    &:nth-child(n + 3) {
      width: 32%;
      padding-bottom: 20%;
      margin-bottom: 0;

      .title {
        font-size: 24px;
      }
    }
  }
}

.img {
  position:absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.title {
  position: absolute;
  left: 12px;
  bottom: 12px;
  font-size: 24px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  letter-spacing: 0.8px;
  color: #fff;
  
  @include media('>=screen-sm') {  
    font-size: 36px;
  }
  @include media('>=screen-md') {  
    font-size: 28px;
    left: 24px;
    bottom: 24px;
  }
  @include media('>=screen-lg-shop') {  
    font-size: 32px;
  }
}

.overlay {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
}
