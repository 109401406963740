@import 'styles/mixins';
@import 'styles/variables';

.sectionDescription {
  margin-bottom: 0;
  @include media('>=screen-sm') {
    max-width: 50%;
  }
}

.section {
  position: relative;
}

.list {
  margin: 32px 0;
  font-size: 20px;
  color: $blue-700;

  @include media('>=screen-sm') {
    font-size: 18px;
  }
  @include media('>=screen-md') {
    margin: 32px 0;
    font-size: 20px;
  }
  @include media('>=screen-lg-shop') {
    font-size: 24px;
  }
}

.listRow {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  cursor: pointer;

  @include media('>=screen-sm') {
    padding-left: 40px;
    padding-bottom: 24px;
  }
}

.link {
  color: $blue-700;
}

.icon {
  position: absolute;
}

.chatIcon {
  font-size: 20px;
  top: -3px;
  left: 4px;

  @include media('>=screen-sm') {
    left: 2px;
    top: -1px;
  }
  @include media('>=screen-lg-shop') {
    font-size: 25px;
  }
}

.envelopIcon {
  left: -5px;
  top: -10px;
  font-size: 35px;

  @include media('>=screen-sm') {
    left: -7px;
    font-size: 40px;
  }
  @include media('>=screen-lg-shop') {
    left: -12px;
    top: -17px;
    font-size: 50px;
  }
}

.phoneIcon {
  font-size: 30px;
  left: 0;
  top: -8px;

  @include media('>=screen-sm') {
    font-size: 35px;
    left: -4px;
    top: -7px;
  }
  @include media('>=screen-lg-shop') {
    font-size: 40px;
    left: -5px;
    top: -7px;
  }
}

.infoIcon {
  font-size: 20px;
  left: 0;
  top: 0;

  @include media('>=screen-sm') {
    font-size: 25px;
    left: 0;
    top: 0;
  }
  @include media('>=screen-lg-shop') {
    font-size: 29px;
    left: 0;
    top: 0;
  }
}

.picture {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;

  @include media('<screen-sm') {
    position: static;
    width: 100%;
  }
}

.image {
  width: 100%;
  object-position: bottom;
  object-fit: contain;
}
