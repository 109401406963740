@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/dg-variables';

.container {
  width: 360px;
  position: relative;

  @include media('<screen-md') {
    width: 100%;
    margin-bottom: 8px;
  }
}

.searchWrapper {
  position: relative;
}

.searchInput {
  height: 48px;
  border-radius: 3px;
  padding-left: 40px !important;
  border: solid 1px $gray-300;
  width: 100%;
  font-size: 14px;
}

.locationIcon {
  position: absolute;
  font-size: 30px;
  top: 50%;
  left: 3px;
  z-index: 2;
  transform: translateY(-50%);
  line-height: 16px;
}

.closeBtn {
  width: 25px;
  height: 25px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 15px;
  border-radius: 50%;
  border: 1px solid $gray-500;
  font-size: 30px;
  display: none;
  justify-content: center;
  align-items: center;
}

.results {
  position: absolute;
  max-height: 350px;
  overflow-y: scroll;
  top: 100%;
  right: 0;
  width: 100%;
  min-width: 270px;
  background: #fff;
  border: solid 1px $gray-300;
  z-index: 999;
  border-top: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.23);
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  transition: all 0.15s linear;
}

.notVisible {
  opacity: 0;
  visibility: hidden;
}

.row {
  padding: 8px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:hover,
  &:focus,
  &:active {
    background: $blue-100;
  }
}

.subRow {
  font-size: 14px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    .subRowContent {
      background: $blue-100;
    }
  }
}

.active {
  background: $blue-100;
  .subRowContent {
    background: $blue-100;
  }
}

.subRowContent {
  display: flex;
  padding: 5px 0;
  align-items: center;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
}

.location {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.locationWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 25px;
}

.locationTitle {
  font-weight: bold;
  font-size: 15px;
}

.locationAddress {
  color: $gray-500;
  font-size: 14px;
}

.noResults {
  font-size: 14px;
  padding: 5px 0;
}


.arrowIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  text-align: center;
}

.icon {
  width: 40px;
  font-size: 30px;
  line-height: 30px;
  padding-right: 10px;
  color: $bg-color-navy;
  text-align: center;
}

.icon-information,
.icon-dive-center {
  font-size: 22px;
}
.icon-diver {
  font-size: 15px;
}
.rowLocationIcon,
.findIcon {
  font-size: 30px;
  color: $gray-500;
}
.shopIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  font-size: 25px;
  &.icon-dive-center {
    position: relative;
    font-size: 18px;
    left: -3px;
  }
}

.closePopupBtn {
  width: 25px;
  height: 25px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 15px;
  border-radius: 50%;
  border: 1px solid $gray-500;
  font-size: 30px;
  display: none;
  justify-content: center;
  align-items: center;
  &.visible {
    display: flex;
  }
}
