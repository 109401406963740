@import 'styles/variables';

.heart {
  z-index: 3;
  height: 32px;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  font-size: 32px;
  color: #fff;
  line-height: 32px;
}

.active {
  color: $red-500;
}

.leftAlign {
  right: auto;
  left: 8px;
}

.wishlistMessage {
  background: $blue-100;
  border: 1px solid $link-color;
  position: absolute;
  width: 300px;
  padding: 15px;
  z-index: 15;
}

.hidden {
  display: none;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.loginLink {
  display: inline-block;
  width: 100%;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
}

.close {
  color: $gray-700;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  text-transform: uppercase;
  cursor: pointer;
}
