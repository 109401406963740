@import 'styles/mixins';
@import 'styles/variables';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}

.item {
  width: 32%;
  padding-bottom: 20%;
  position: relative;
  margin-bottom: 32px;
  cursor: pointer;

  @include media('<=screen-md') {
    padding-bottom: 25%;
    margin-bottom: 18px;
  }
  @include media('<=screen-sm') {
    width: 49%;
    padding-bottom: 35%;
    margin-bottom: 10px;
  }

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 60%;
    left: 0;
    right: 0;
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  }
}

.title {
  position: absolute;
  bottom: 10px;
  left: 15px;
  color: #fff;
  font-weight: bold;
  z-index: 2;
  font-size: 26px;
}


.image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}
