$bg-color-navy: #002547;
$bg-color-blue: #0070D3;
$color-white: #ffffff;
$color-black: #000000;
$color-light-gray: #8494A4;
$color-light-darkest: #002547;
$color-alice-blue: #EAF0F5;
$color-dg-gray: #657381;
$color-dg-light-gray: #D7DEE4;
$color-dg-bg-light-gray: #f7f7f7;
$color-dg-navy: #1e80bf;
$color-dg-light-bg-gray: #F4F8FB;
$color-dg-gray-darker: #657381;
$color-dg-red: #f23d4e;

$breakpoint-desktop-big: 1200px;
$breakpoint-reg-desktop: 1160px;
$breakpoint-pre-desktop: 1100px;
$breakpoint-desktop: 992px;
$breakpoint-pre-tablet: 840px;
$breakpoint-tablet: 768px;
$breakpoint-xs: 576px;
