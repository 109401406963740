@import 'styles/mixins';
@import 'styles/variables';

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 auto;
  max-width: 570px;
  background: $blue-900;
  border-radius: 3px;
  box-shadow: $box-shadow-default;
  padding: 15px 24px;

  @include media('<screen-md') {
    padding: 8px 10px;
    flex-direction: column;
  }
}

.button {
  width: 140px;
  min-height: 48px;
  font-size: 18px;
  align-self: center;
  @include media('<screen-md') {
    width: 100%;
  }
}


.mobileSearch {
  position: fixed;
  max-width: 100%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
  transition: 0.5s;
  .button {
    display: none;
  }
}

// .open-modal
//   overflow: hidden
//   position: fixed
//   width: 100%
//   height: 100%
//   left: 0
//   top: 0
//   .homepage-hero.adventure-hero-section .hero-search
//     &.mobile-destination
//       padding: 15px
//       transform: translateY(0)
//       width: 100%

//   .mobile-destination
//     .btn-red,
//     .hero-search__field
//       &:nth-child(n+2)
//         opacity: 0
//   .country-list-wrapper
//     height: calc(100vh - 150px)
//     overflow: scroll
//   .list-of-countires
//     box-shadow: none

