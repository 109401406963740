@import 'styles/variables';
@import 'styles/mixins';
.bar {
  background: #5d6063;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px 15px;
  @media (max-width: $screen-sm - 1) {
    justify-content: center;
    align-items: center;
    display: none;
  }
  a,
  span {
    color: #fff;
  }
  .infoIcon {
    font-size: 25px;
    line-height: 14px;
    vertical-align: middle;
  }
  .tooltip {
    z-index: 9999;
    font-size: 12px;
    background: $padi-grey;
    text-transform: none;
    color: #fff;
    border: 1px solid $padi-grey !important;
    &:before {
      border-bottom-color: $padi-grey;
    }
    &:after {
      border-bottom-color: $padi-grey;
    }
  }
  .shareBlock {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: -5px;
    text-transform: none;
    background: $padi-grey;
    z-index: 9999;
    width: calc(100% + 20px);
    a {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      padding: 10px 15px;
      &:hover {
        background-color: #8e8e8e;
      }
    }
    span {
      position: relative;
      padding-left: 20px;
      margin-bottom: 10px;
      margin-right: 0;
      vertical-align: middle;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        text-decoration: none;
      }

    }

    &.placeholderAnimation {
      span {
        width: 90%;
        height: 14px;
      }
    }
  }
}


.column {
  position: relative;
  text-transform: uppercase;
  @media (max-width: 860px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  @media (max-width: $screen-xs - 1) {
    justify-content: space-between;

    &:first-child {
      justify-content: center;
    }
  }
  a,
  span {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }

    i {
      margin-right: 5px;
    }
    @media (max-width: $screen-md - 1) {
      margin-right: 10px;
    }
  }

  span:hover {
    .shareBlock {
      display: flex;
    }
  }
}

.infoIcon {
  font-size: 20px;
}

.bottomArrowIcon {
  font-size: 20px;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}

