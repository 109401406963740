@import 'styles/mixins';

.collections {
  width: 100%;
  margin: 24px 0;
}

.item {
  position: relative;
  height: 200px;
  width: 145px;

  @include media('>=screen-sm') {
    height: 260px;
    width: 190px;
  }
}

.image {
  position: absolute;
  border-radius: 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.title {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 6px;
  padding: 0 16px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #ffffff;

  @include media('>=screen-md') {
    font-size: 20px;
  }

  @include media('>=screen-lg-shop'){
    font-size: 24px;
  }
}

.overlay {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), #000);
}
