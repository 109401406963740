@import 'styles/mixins';
@import 'styles/variables';

.container {
  width: 900px;
  background: #fff;
  border-radius: 3px;
  box-shadow: $box-shadow-default;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: auto;
  margin-bottom: -38px;
  @include media('<screen-sm'){
    width: 100%;
    margin-bottom: -40px;
  }
}

.shopType {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
  color: $gray-700;
  width: 16.66%;
  cursor: pointer;
  text-decoration: none;

  &:hover,&:active,&:focus {
    text-decoration: none;
    color: $gray-700;
    background-color: $blue-300;
  }

  @include media('<screen-sm') {
    width: 33.333%;
  }
  @include media('<=screen-xs') {
    padding: 5px 0;
  }
}

.icon {
  display: flex;
  font-size: 40px;
  width: 100%;
  height: 35px;
  align-items: center;
  justify-content: center;

  @include media('<=screen-xs') {
    height: 25px;
    font-size: 28px;
  }
}

.liveaboardIcon {
  font-size: 55px;

  @include media('<=screen-xs') {
    font-size: 40px;
  }
}

.iconDiveResort {
  font-size: 40px;
}

.iconSnorkeling {
  font-size: 40px;
  @include media('<=screen-xs') {
    font-size: 25px;
  }
}

.iconDiveCenter {
  font-size: 27px;
  @include media('<=screen-xs') {
    font-size: 20px;
  }
}
.iconDiver {
  font-size: 20px;
  @include media('<=screen-xs') {
    font-size: 16px;
  }
}
.iconCourse {
  font-size: 25px;
  @include media('<=screen-xs') {
    font-size: 20px;
  }
}

.title {
  font-size: 16px;
  text-align: center;
  @include media('<=screen-xs') {
    font-size: 14px;
  }
}
