@import 'styles/variables';

.trustpilotWidget {
  width: 100%;
  margin-top: 24px;
  position: relative;
  padding: 0;

  &:before {
    content: '';
    position: absolute;
    top: -24px;
    left: -100%;
    right: -100%;
    display: block;
    height: 1px;
    background: $gray-300;
  }
}

