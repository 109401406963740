.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 15px;

  &.mobile {
    right: 50px;
  }
}

.loadingDot {
  animation: dot ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  margin: 4px;
  height: 5px;
  width: 5px;
  border-radius: 4px;
}

.loadingDot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loadingDot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    background-color: #a9a9a9;
    transform: scale(1);
  }
  50% {
    background-color: #868686;
    transform: scale(1.3);
  }
  100% {
    background-color: #a9a9a9;
    transform: scale(1);
  }
}
