@import 'styles/mixins';

.wrapper {
	display: flex;
  flex-wrap: wrap;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 30px 30px 30px 0;
  @include media('<=screen-sm') {
    width: 100%;
  }
}

.text {
  font-size: 18px;
}

.imgWrapper {
  position: relative;
  width: 50%;
  height: 400px;
  @include media('<=screen-sm') {
    display: none;
  }
}

.picture {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
