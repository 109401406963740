.container {
  background-color: #003059;
  padding: 10px;
  color: #ddd;
}


.wrapper {
  max-width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  font-size: 30px;
  line-height: 15px;
  cursor: pointer;
}
